:root{
--bittersweet: #f27059ff;
--gunmetal: #012a36ff;
--honeydew: #eaf2e3ff;
}

body{
    background-color: var(--gunmetal);
    color: var(--honeydew);
}
*{
    box-sizing: border-box;
}
main{
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow: hidden;
}
form{
    border: 1px solid var(--honeydew);
    border-radius: 10px;
    padding: 5px;
    display:flex;
    width: 100%;
}
form input[type="text"] {
    background: none;
    color: var(--honeydew);
    border:0;
    padding: 0 8px;
    display:block;
    width: 100%;
    height: 30px;
}
form input[type="text"]:focus{
    outline: none;
    color: var(--bittersweet);
}
form button{
    background-color: var(--bittersweet);
    border:0;
    border-radius: 6px;
    cursor:pointer;
    width: 30px;
    font-size: 1.2rem;
    color: var(--honeydew);
}
div.tasks{
    width: 100%;
    margin-top: 10px;
    overflow: scroll;
    flex-grow: 1;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}
div.tasks::-webkit-scrollbar {
    display: none;
}
div.task{
    background-color: var(--honeydew);
    border-radius: 10px;
    padding: 5px 9px;
    margin-top: 5px;
    display:flex;
    align-items: center;
    transition: opacity .3s linear;
    color: var(--gunmetal);
    height: 45px;
    width: 100%;
}
input[type="text"]:focus{
    color: var(--gunmetal);
}
div.task svg{
    height: 20px;
    margin-right: 6px;
    fill: var(--bittersweet);
}
div.task.done{
    opacity: 0.3;
}
div.task-name, div.task form{
    flex-grow:1;
}
div.task span{
    position:relative;
}
div.task span:before{
    content:'';
    display:block;
    width:0;
    height:1px;
    background-color: #ddd;
    position:absolute;
    top: 10px;
    transition: width .1s linear;
}
div.task.done span:before{
    width: 100%;
}

h1, h2{
    text-align: center;
}

div.checkbox{
    display:flex;
    align-items: center;
}

button.trash{
    background:none;
    border:none;
    display:flex;
    cursor:pointer;
}
button.trash svg{
    fill: #888;
    height: 14px;
}
div.task input{
    border-bottom: 1px solid #555;
    padding: 2px 4px;
    color: var(--gunmetal);
}
.logo{
    max-width: 120px;
    margin: 30px;
}